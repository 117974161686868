.noscript {
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  position: fixed;
  top:0;
  left: 0;
  background-color: rgba(0, 0, 0, .6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: start;
}
.noscript .noscript__content {
  box-sizing: border-box;
  background-color: white;
  border-radius: 0 0 5px 5px;
  color: indianred;
  z-index: 10000;
  display: flex;
  overflow: hidden;
}

.noscript .noscript__image {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffbd00;
}

.noscript .noscript__image img {
  width: 21px;
  height: 19px;
}

.noscript__text-side {
  display: inline-block;
  padding: 14px 28px 16px 28px;
}

.noscript .noscript__title {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f4f4f;
}
.noscript .noscript__text {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4f4f4f;
}