@import "~reset-css";

@import "vars";
@import "fontface";

@import "_nojs";
@import "_mobile";

* {
  box-sizing: border-box;
  outline: none;
  -webkit-font-smoothing: antialiased;
}

textarea:-moz-placeholder,
input:-moz-placeholder {
  color: #7d7d7d;
  font-family: $roboto_regular;
}

textarea::-moz-placeholder,
input::-moz-placeholder {
  color: #7d7d7d;
  font-family: $roboto_regular;
}

textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: #7d7d7d;
  font-family: $roboto_regular;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: #7d7d7d;
  font-family: $roboto_regular;
}

a {
  text-decoration: none;
}

img {
  display: table;
}

body {
  font: 14px $roboto_regular;
}

input,
button {
  border: none;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

.page-inner {
  display: flex;
  width: 100%;
  height: 100vh;
}

.page-auth {
  background-color: #F4F4F4;
  color: #343434;
  width: 100%;
  height: 100vh;
  margin: 0;
}

.page-auth__content {
  width: 394px;
  padding: 40px;
  margin: 33px auto 60px;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  a {
    color: #005CB9;
  }
}

.page-auth__logo {
  display: flex;
  justify-content: center;
  margin: 10px 0 30px 0;
}

.page-auth__logo-text {
  color: #2C86F1;
  font-family: $roboto_medium;
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 22px;
  letter-spacing: 0.4px;
}

.page-auth__content__form {
  .input {
    border-bottom: 1px solid #BDBDBD;
    color: #323232;
    font: 16px Roboto-Light,Arial,sans-serif;
    margin-bottom: 31px;
    overflow-x: hidden;
    padding-bottom: 5px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .input + label {
    display: block;
    position: relative;
  }

  .input + label > span {
    -webkit-transition: all .2s ease;
    color: #8F8F8F;
    left: 0;
    position: absolute;
    top: -55px;
    transition: all .2s ease;
    width: 100%;
    z-index: 100;
  }

  .input_pswd + label span {
    top: -24px;
  }

  .input:focus + label > span,
  .input:valid + label > span {
    font-size: 13px;
    top: -75px;
  }

  .input_pswd:focus + label > span,
  .input_pswd:valid + label > span {
    top: -44px;
  }

  .error {
    display:none;
    margin: 16px 0;
    color: darkred;
  }

  a {
    display: block;
  }

  > a {
    text-align: center;
    text-decoration: underline;
  }

  form a {
    margin-top: 16px;
  }

  .button {
    width: 100%;
    background-color: #2C86F1;
    color: #fff;
    height: 40px;
    margin: 16px 0;
    border-radius: 6px;
    cursor: pointer;
  }
}

.page-auth__content__title {
  font: 24px $roboto_medium;
  color: #1E1E1E;
  margin-bottom: 39px;
}